import React, {useState} from 'react'
import {  useDispatch } from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {useTranslation} from 'react-i18next'
import OTPInput from 'react-otp-input'
import { store } from "../../reducers"
import HeaderPages from '../../components/header/HeaderPages'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import {Footer} from '../../components/Footer/Footer'
import axios from 'axios'
import verifyImg from '../../assets/images/verify.svg'

export const Verify = () => {
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false)
  const [VerifyData, setVerifyData] = useState<any>()
  const [otp, setOtp] = useState('')
  const {t} = useTranslation()

  const navigate = useNavigate()

  const phone_user = localStorage.getItem('phone_user')
  const id_token = localStorage.getItem('id_token')

  const userLogin = (payload : any) => {
    const action = { type: 'LOGGED_IN', payload: payload}
    dispatch(action);
  }
  const CodeVerify = useFormik({
    initialValues: {
      code_1: '',
      code_2: '',
      code_3: '',
      code_4: '',
    },
    onSubmit: async (values) => {
      const config = {
        headers: {Authorization: `Bearer ${id_token}`},
      }
      axios
        .post( 'https://api.sm.org.sa/api/confirm-account',  {  code: otp, },  config )
        .then((response) => {
          setSuccess(true)
          // console.log(response.data.token);
          // window.localStorage.setItem('id_token', response.data.token)
          setVerifyData(response.data);
          userLogin(response.data);
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          response.status ? 
          setTimeout(() => {
            navigate('/');
            window.location.reload();
          }, 200)
          : 'success'
        })
        .catch((err) => {
          console.log(err)
        })
    },
  })

  return (
    <div className="tcon-pages-wrapper">
      <HeaderPages />
      <div className="container mt-20 sm:mx-auto">
        <Breadcrumb title={'التحقق من الرمز'} />
        <div className="tcon-about-section m-auto w-9/12 py-10 lg:py-10">
          <div className="relative isolate mx-auto xl:container">
            <form onSubmit={CodeVerify.handleSubmit}>
              <div className="space-y-12">
                <div className="rounded-xl border-gray-900/10 bg-slate-100 p-5 pb-12 lg:p-16">
                  <div className="flex flex-wrap items-start items-center justify-between p-6 lg:px-8">
                    <div className="flex-2 w-100 lg:w-50 items-center lg:flex-1">
                      <h2 className="text-center text-xl font-semibold leading-7 text-gray-900">
                        التحقق من الرمز
                      </h2>
                      <h2 className="text-center text-xl font-semibold leading-7 text-gray-900">
                        تم ارسال رمز التفعيل إلى رقم جوالك
                      </h2>

                      <p className="text-center text-xl font-semibold leading-7 text-gray-900">
                        {phone_user}
                      </p>

                      <div className="mt-10 grid grid-cols-1 gap-x-1 gap-y-1 sm:grid-cols-1">
                        <div className="sm:col-span-3">
                          <div className="my-8 flex OTPInput">
                            <OTPInput
                              onChange={setOtp}
                              value={otp}
                              inputStyle="inputStyle"
                              numInputs={4}
                              renderInput={(props) => <input {...props} />}
                            />
                          </div>
                        </div>

                        <div className="col-span-full">
                          <button
                            type="submit"
                            className="bg-primary_color rounded-md bg-indigo-600 px-24 py-5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            {t('send')}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="flex-2 w-100 lg:w-50 items-center lg:flex-1">
                        <img src={verifyImg} alt='verify' />
                    </div>
                  </div>
                </div>
              </div>
            </form>
            {VerifyData && VerifyData?.status === false && (
              <p className="tcon-msg mb-4 rounded-lg bg-red-50 p-4 text-sm text-red-800 dark:bg-gray-800 dark:text-red-400">
                {VerifyData && VerifyData?.msg}
              </p>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Verify
