import axios from 'axios'
import {useFormik} from 'formik'
import 'moment-hijri'
import {Fragment, useEffect, useState} from 'react'
import arabic from 'react-date-object/calendars/arabic'
import gregorian from 'react-date-object/calendars/gregorian'
import arabic_ar from 'react-date-object/locales/arabic_ar'
import gregorian_ar from 'react-date-object/locales/gregorian_ar'
import {useTranslation} from 'react-i18next'
import DatePicker from 'react-multi-date-picker'
import {Link, useNavigate} from 'react-router-dom'
import {Bounce, ToastContainer, toast} from 'react-toastify'
import * as Yup from 'yup'
import upl from '../../assets/images/upl.svg'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import {Footer} from '../../components/Footer/Footer'
import HeaderPages from '../../components/header/HeaderPages'
import {store} from '../../reducers'
import {ApiService} from '../../services/data.service'

export const Edit = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const [options, setOptionsnData] = useState<any>()
  const [attributes, setAttributesnData] = useState<any>()
  const [success, setSuccess] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [userData, setUserData] = useState<any>()
  const [value, setValue] = useState(new Date())

  const [dateFormat, setDateFormat] = useState('gregorian')

  const lang = localStorage.getItem('site_lang') || 'ar'

  const handleDateFormatChange = (e: any) => {
    setDateFormat(e.target.value)
  }

  const userDate = store.getState().authStoreState.token

  const getAuthHeader = () => ({
    Authorization: `Bearer ${userDate}`,
    'Accept-Language': lang,
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*',
    'content-type': 'multipart/form-data',
  })

  const [organizationType, setOrganizationType] = useState<any>()
  const [organizationName, setOrganizationName] = useState<any>()
  const [organizationLocation, setOrganizationLocation] = useState<any>()
  const [organizationPhone, setOrganizationPhone] = useState<any>()
  const [organizationWebsite, setOrganizationWebsite] = useState<any>()
  const [organizationEmail, setOrganizationEmail] = useState<any>()
  const [organizationTaxNumber, setOrganizationTaxNumber] = useState<any>()
  const [organizationLicenseNumber, setOrganizationLicenseNumber] =
    useState<any>()
  const [organizationLicenseDate, setOrganizationLicenseDate] = useState<any>()
  const [commissionerAttribute, setCommissionerAttribute] = useState<any>()
  const [commissionerName, setCommissionerName] = useState<any>()
  const [idNumber, setIdNumber] = useState<any>()
  const [authorizationLetter, setAuthorizationLetter] = useState<any>()
  const Register = useFormik({
    initialValues: {
      organization_name: '',
      email: '',
      phone: '',
      file: '',
      link: '',
      license_number: '',
      organization_type_id: '',
      license_date: '',
      location: '',
      website: '',
      commissioner_name: '',
      commissioner_attribute_id: '',
      tax_number: '',
      authorization_letter: '',
      // agreement_letter: '',
      id_number: '',
    },
    // validationSchema: SignupSchema,
    onSubmit: async (values) => {
      const defaultValues = {
        organization_name: organizationName,
        email: organizationEmail,
        phone: organizationPhone,
        license_number: organizationLicenseNumber,
        organization_type_id: organizationType,
        commissioner_attribute_id: commissionerAttribute,
        license_date: organizationLicenseDate,
        location: organizationLocation,
        website: organizationWebsite,
        commissioner_name: commissionerName,
        tax_number: organizationTaxNumber,
        authorization_letter: '',
        id_number: idNumber,
      }

      const finalValues = {
        ...values,
        identity_link: values?.file || values?.link,
        link_or_file: formData?.radioOption == 'file' ? 2 : 1,
        organization_name:
          values.organization_name || defaultValues.organization_name,
        email: values.email || defaultValues.email,
        phone: values.phone || defaultValues.phone,
        license_number: values.license_number || defaultValues.license_number,
        organization_type_id:
          values.organization_type_id || defaultValues.organization_type_id,
        commissioner_attribute_id:
          values.commissioner_attribute_id ||
          defaultValues.commissioner_attribute_id,
        license_date: values.license_date || defaultValues.license_date,
        location: values.location || defaultValues.location,
        website: values.website || defaultValues.website,
        commissioner_name:
          values.commissioner_name || defaultValues.commissioner_name,
        tax_number: values.tax_number || defaultValues.tax_number,
        authorization_letter:
          values.authorization_letter || defaultValues.authorization_letter,
        id_number: values.id_number || defaultValues.id_number,
      }

      setSuccess(true)
      axios
        .post(
          'https://api.sm.org.sa/api/media/user/editProfile',
          finalValues,
          {
            headers: getAuthHeader(),
          }
        )
        .then((response: any) => {
          setSuccess(false)
          if (response.status === true) {
            setSuccess(false)
            toast.success(response.msg, {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'colored',
              transition: Bounce,
            })
          } else {
            setSuccess(false)
            toast.error(response.data, {
              position: 'top-center',
              autoClose: false,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'colored',
              transition: Bounce,
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
  })
  const [formData, setFormData] = useState({
    radioOption: 'link',
  })
  useEffect(() => {
    ApiService.getProfile()
      .then((response) => {
        console.log(response.data, 'response.data')
        setUserData(response.data)
        setOrganizationType(response.data?.organization_type_id.id)
        setOrganizationName(response.data?.name)
        setOrganizationLocation(response.data?.location)
        setOrganizationPhone(response.data?.phone)
        setOrganizationWebsite(response.data?.website)
        setOrganizationEmail(response.data?.email)
        setOrganizationTaxNumber(response.data?.tax_number)
        setOrganizationLicenseNumber(response.data?.license_number)
        setOrganizationLicenseDate(response.data?.license_date)
        setCommissionerAttribute(response.data?.commissioner_attribute_id.id)
        setCommissionerName(response.data?.commissioner_name)
        setIdNumber(response.data?.id_number)
        setFormData({
          radioOption: response.data?.link_or_file == 1 ? 'link' : 'file',
        })
        Register.setFieldValue('link', response.data?.link ?? '')
      })
      .catch((err) => {
        console.log(err)
      })
    ApiService.organizationTypes()
      .then((response) => {
        setOptionsnData(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
    ApiService.commissionerAttributes()
      .then((response) => {
        setAttributesnData(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const regex = new RegExp(
    /^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/
  )

  const exceptThisSymbols = ['e', 'E', '+', '-', '.']

  const SignupSchema = Yup.object().shape({
    organization_name: Yup.string().required('الرجاء اضافة اسم المنظمة'),
    email: Yup.string().email(t('emailerror')).required(t('emailerror')),
    password: Yup.string()
      .required('الرجاء اضافة الرقم السري')
      .min(2, 'Too Short!')
      .max(50, 'Too Long!'),
    phone: Yup.string()
      .matches(regex, 'الرجاء ادخال رقم صحيح')
      .required('الرجاء اضافة رقم الجوال'),
    license_number: Yup.string().required('الرجاء اضافة رقم الترخيص'),
    organization_type_id: Yup.string().required('الرجاء اختيار نوع المنظمة'),
    license_date: Yup.string().required('الرجاء اضافة تاريخ الترخيص'),
    location: Yup.string().required('الرجاء اضافة المقر'),
    website: Yup.string().required('الرجاء اضافة الموقع الالكترونى'),
    commissioner_name: Yup.string().required('الرجاء اضافة اسم المفوض'),
    tax_number: Yup.string().required('الرجاء اضافة رقم التسجيل الضريبي'),
    commissioner_attribute_id: Yup.string().required('الرجاء اضافة صفة المفوض'),
    authorization_letter: Yup.mixed()
      .required('الرجاء رفع خطاب التفويض')
      .test('fileFormat', t('commissionerlattermsg'), (value: any) => {
        if (value) {
          const supportedFormats = ['pdf']
          return supportedFormats.includes(value.name.split('.').pop())
        }
        return true
      })
      .test('fileSize', t('commissionerlattermsg'), (value: any) => {
        if (value) {
          return value.size <= 3145728
        }
        return true
      }),
    link: Yup.string(),
    file: Yup.mixed()
      .test('fileFormat', t('commissionerlattermsg'), (value: any) => {
        if (value) {
          const supportedFormats = ['pdf']
          return supportedFormats.includes(value.name.split('.').pop())
        }
        return true
      })
      .test('fileSize', t('commissionerlattermsg'), (value: any) => {
        if (value) {
          return value.size <= 3145728
        }
        return true
      }),
    // agreement_letter: Yup.mixed()
    //   .required('الرجاء رفع خطاب الموافقة')
    //   .test('fileFormat', t('commissionerlatteraccmsg'), (value: any) => {
    //     if (value) {
    //       const supportedFormats = ['pdf']
    //       return supportedFormats.includes(value.name.split('.').pop())
    //     }
    //     return true
    //   })
    //   .test('fileSize', t('commissionerlatteraccmsg'), (value: any) => {
    //     if (value) {
    //       return value.size <= 3145728
    //     }
    //     return true
    //   }),
    // id_number: Yup.string()
    //   .required('الرجاء اضافة رقم الهوية الوطنية')
    //   .min(2, 'الرقم قصير جداً')
    //   .max(10, 'الرقم طويل جداً'),
    // checkbox: Yup.bool().oneOf([true], 'الرجاء الموافقة علي الشروط و الاحكام'),
  })

  const handleChange = (e) => {
    const {name, value, type, checked, files} = e.target
    setFormData((prevState) => ({
      ...prevState,
      [name]:
        type === 'checkbox' ? checked : type === 'file' ? files[0] : value,
    }))
  }

  return (
    <div className="tcon-pages-wrapper">
      <HeaderPages />
      <div className="container mx-auto mt-20">
        <Breadcrumb title={t('editProfile')} />
        <div className="tcon-about-section py-20">
          <div className="grid grid-cols-1 items-center gap-x-8 px-8 py-10">
            <form onSubmit={Register.handleSubmit}>
              <div className="media-form-block">
                <h2 className="mt-6 px-4 text-start text-xl font-semibold leading-7 text-gray-900">
                  {t('registerInfo')}
                </h2>
                <div className="flex flex-wrap items-start justify-between md:justify-start">
                  <div className="flex-2 lg:w-50 w-full items-center lg:flex-1">
                    <div className="px-4">
                      <div className="m-auto my-8">
                        <label
                          htmlFor="organization_type_id"
                          className="mb-2 block text-start text-sm font-medium leading-6 text-gray-900"
                        >
                          {t('typeorganization')}
                        </label>
                        <select
                          className="mb-3 block w-full rounded-md border-0 px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          name="organization_type_id"
                          id="organization_type_id"
                          onChange={(event) => {
                            const {value} = event.target
                            value === userData?.organization_type_id.id
                            setOrganizationType(value)
                            Register.setFieldValue(
                              'organization_type_id',
                              value
                            )
                          }}
                          onBlur={Register.handleBlur}
                          value={organizationType}
                        >
                          {options &&
                            options.map((item: any, index: number) => (
                              <option key={index} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                        </select>
                        {Register.errors.organization_type_id &&
                          Register.touched.organization_type_id && (
                            <div className="text-sm text-red-600">
                              {Register.errors.organization_type_id}
                            </div>
                          )}
                      </div>
                      <div className="m-auto my-8">
                        <label
                          htmlFor="license_number"
                          className="mb-2 block text-start text-sm font-medium leading-6 text-gray-900"
                        >
                          {t('organizationnumber')}
                        </label>
                        <input
                          type="text"
                          name="license_number"
                          id="license_number"
                          onChange={(e: any) => {
                            setOrganizationLicenseNumber(
                              e.target.value.replace(/[^0-9]/g)
                            )
                            Register.setFieldValue(
                              'license_number',
                              e.target.value.replace(/[^0-9]/g, '')
                            )
                          }}
                          onBlur={Register.handleBlur}
                          defaultValue={userData?.license_number}
                          value={organizationLicenseNumber}
                          placeholder={t('organizationnumber')}
                          autoComplete="family-name"
                          className="mb-3 block w-full rounded-md border-0 px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        {Register.errors.license_number &&
                          Register.touched.license_number && (
                            <div className="text-sm text-red-600">
                              {Register.errors.license_number}
                            </div>
                          )}
                      </div>
                      <div className="m-auto my-8">
                        <label
                          htmlFor="location"
                          className="mb-2 block text-start text-sm font-medium leading-6 text-gray-900"
                        >
                          {t('organizatiolocation')}
                        </label>
                        <input
                          type="location"
                          name="location"
                          id="location"
                          onChange={(event) => {
                            const {value} = event.target
                            setOrganizationLocation(value)
                            Register.setFieldValue('organization_name', value)
                          }}
                          onBlur={Register.handleBlur}
                          value={organizationLocation}
                          placeholder={t('organizatiolocation')}
                          autoComplete="family-name"
                          className="mb-3 block w-full rounded-md border-0 px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        {Register.errors.location &&
                          Register.touched.location && (
                            <div className="text-sm text-red-600">
                              {Register.errors.location}
                            </div>
                          )}
                      </div>
                      <div className="m-auto my-8">
                        <label
                          htmlFor="website"
                          className="mb-2 block text-start text-sm font-medium leading-6 text-gray-900"
                        >
                          {t('organizatiowebsite')}
                        </label>
                        <input
                          type="website"
                          name="website"
                          id="website"
                          onChange={(event) => {
                            const {value} = event.target
                            setOrganizationWebsite(value)
                            Register.setFieldValue('website', value)
                          }}
                          onBlur={Register.handleBlur}
                          value={organizationWebsite}
                          placeholder={t('organizatiowebsite')}
                          autoComplete="family-name"
                          className="mb-3 block w-full rounded-md border-0 px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        {Register.errors.website &&
                          Register.touched.website && (
                            <div className="text-sm text-red-600">
                              {Register.errors.website}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="flex-2 lg:w-50 w-full items-center lg:flex-1">
                    <div className="px-4">
                      <div className="m-auto my-8">
                        <label
                          htmlFor="organization_name"
                          className="mb-2 block text-start text-sm font-medium leading-6 text-gray-900"
                        >
                          {t('organizationName')}
                        </label>
                        <input
                          type="organization_name"
                          name="organization_name"
                          id="organization_name"
                          onChange={(event) => {
                            const {value} = event.target
                            setOrganizationName(value)
                            Register.setFieldValue('organization_name', value)
                          }}
                          onBlur={Register.handleBlur}
                          value={organizationName}
                          placeholder={t('organizationName')}
                          autoComplete="family-name"
                          className="mb-3 block w-full rounded-md border-0 px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        {Register.errors.organization_name &&
                          Register.touched.organization_name && (
                            <div className="text-sm text-red-600">
                              {Register.errors.organization_name}
                            </div>
                          )}
                      </div>
                      <div className="m-auto my-8">
                        <label
                          htmlFor="license_date"
                          className="mb-2 block text-start text-sm font-medium leading-6 text-gray-900"
                        >
                          {t('organizationdate')}
                        </label>
                        <div className="flex">
                          <select
                            value={dateFormat}
                            onChange={handleDateFormatChange}
                            className="mb-3 me-4 block w-1/4 rounded-md border-0 px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          >
                            <option value="gregorian">{t('Gregorian')}</option>
                            <option value="hijri">{t('Hijri')}</option>
                          </select>
                          <DatePicker
                            calendar={
                              dateFormat === 'gregorian' ? gregorian : arabic
                            }
                            locale={
                              dateFormat === 'gregorian'
                                ? gregorian_ar
                                : arabic_ar
                            }
                            onChange={(date: any) => {
                              const dateValue =
                                date?.year +
                                  '-' +
                                  date?.month +
                                  '-' +
                                  date?.day || date
                              setValue(dateValue)
                              setStartDate(dateValue)
                              setOrganizationLicenseDate(dateValue)
                              Register.setFieldValue('license_date', dateValue)
                            }}
                            value={startDate || organizationLicenseDate}
                            maxDate={new Date()}
                            inputClass="mb-3 block w-full rounded-md border-0 px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                        {Register.errors.license_date &&
                          Register.touched.license_date && (
                            <div className="text-sm text-red-600">
                              {Register.errors.license_date}
                            </div>
                          )}
                      </div>
                      <div className="m-auto my-8">
                        <label
                          htmlFor="association_name"
                          className="mb-2 block text-start text-sm font-medium leading-6 text-gray-900"
                        >
                          {t('vatNumber')}
                        </label>
                        <input
                          type="tax_number"
                          name="tax_number"
                          id="tax_number"
                          onChange={(e: any) => {
                            setOrganizationTaxNumber(value)
                            Register.setFieldValue(
                              'tax_number',
                              e.target.value.replace(/[^0-9]/g, '')
                            )
                          }}
                          onBlur={Register.handleBlur}
                          value={organizationTaxNumber}
                          placeholder={t('vatNumber')}
                          autoComplete="family-name"
                          className="mb-3 block w-full rounded-md border-0 px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        {Register.errors.tax_number &&
                          Register.touched.tax_number && (
                            <div className="text-sm text-red-600">
                              {Register.errors.tax_number}
                            </div>
                          )}
                      </div>
                      <div className="m-auto my-8">
                        <label
                          htmlFor="email"
                          className="mb-2 block text-start text-sm font-medium leading-6 text-gray-900"
                        >
                          {t('organizatioemail')}
                        </label>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          onChange={(event) => {
                            const {value} = event.target
                            setOrganizationEmail(value)
                            Register.setFieldValue('email', value)
                          }}
                          onBlur={Register.handleBlur}
                          value={organizationEmail}
                          placeholder={t('organizatioemail')}
                          autoComplete="family-name"
                          className="mb-3 block w-full rounded-md border-0 px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        {Register.errors.email && Register.touched.email && (
                          <div className="text-sm text-red-600">
                            {Register.errors.email}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="media-form-block">
                <h2 className="mt-6 px-4 text-start text-xl font-semibold leading-7 text-gray-900">
                  {t('commissionerinfo')}
                </h2>
                <div className="flex flex-wrap items-start justify-between">
                  <div className="flex-2 lg:w-50 w-full items-center lg:flex-1">
                    <div className="px-4">
                      <div className="m-auto my-8">
                        <label
                          htmlFor="phone"
                          className="mb-2 block text-start text-sm font-medium leading-6 text-gray-900"
                        >
                          {t('commissionername')}
                        </label>
                        <input
                          type="commissioner_name"
                          name="commissioner_name"
                          id="commissioner_name"
                          onChange={(event) => {
                            const {value} = event.target
                            setCommissionerName(value)
                            Register.setFieldValue('commissioner_name', value)
                          }}
                          onBlur={Register.handleBlur}
                          value={commissionerName}
                          placeholder={t('commissionername')}
                          autoComplete="family-name"
                          className="mb-3 block w-full rounded-md border-0 px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        {Register.errors.commissioner_name &&
                          Register.touched.commissioner_name && (
                            <div className="text-sm text-red-600">
                              {Register.errors.commissioner_name}
                            </div>
                          )}
                      </div>
                      <div className="m-auto my-8">
                        <label
                          htmlFor="authorization_letter"
                          className="mb-2 block text-start text-sm font-medium leading-6 text-gray-900"
                        >
                          {t('commissionerlatter')}
                        </label>
                        <label className="mb-3 block w-full rounded-md border-0 bg-white px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                          <input
                            type="file"
                            name="authorization_letter"
                            onChange={(e: any) => {
                              setAuthorizationLetter(e.target.files[0])
                              Register.setFieldValue(
                                'authorization_letter',
                                e.target.files[0]
                              )
                            }}
                            id="authorization_letter"
                            accept="application/pdf"
                          />
                        </label>
                        {Register.errors.authorization_letter &&
                          Register.touched.authorization_letter && (
                            <div className="text-sm text-red-600">
                              {Register.errors.authorization_letter}
                            </div>
                          )}
                        <Link
                          to={userData?.authorization_letter}
                          target="_blank"
                        >
                          <div className="file_itm flex items-center justify-between">
                            <div className="fle_icon me-3 flex items-center">
                              <span>
                                <img src={upl} alt="file icon" />
                              </span>
                              <div className="fle_txt ms-4">
                                <p>
                                  <span>{t('commissionerlatter')}</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="m-auto my-8">
                        <label
                          htmlFor="phone"
                          className="mb-2 block text-start text-sm font-medium leading-6 text-gray-900"
                        >
                          {t('phonenumber')}
                        </label>
                        <input
                          type="phone"
                          name="phone"
                          id="phone"
                          maxLength={10}
                          onChange={(event) => {
                            const {value} = event.target
                            setOrganizationPhone(value)
                            Register.setFieldValue(
                              'phone',
                              value.replace(/[^0-9]/g, '')
                            )
                          }}
                          onBlur={Register.handleBlur}
                          value={organizationPhone}
                          placeholder="05xxxxxxxx"
                          autoComplete="family-name"
                          className="mb-3 block w-full rounded-md border-0 px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        {Register.errors.phone && Register.touched.phone && (
                          <div className="text-sm text-red-600">
                            {Register.errors.phone}
                          </div>
                        )}
                      </div>
                      <div className="m-auto my-8">
                        <label
                          htmlFor="radioOption"
                          className="mb-2 block text-start text-sm font-medium leading-6 text-gray-900"
                        >
                          {t('AttachBusinessFile')} <bdi>*</bdi>
                        </label>
                        <div className="my-6">
                          <label className="me-3">
                            <input
                              type="radio"
                              name="radioOption"
                              value="link"
                              datatype="link"
                              className="me-2"
                              checked={formData.radioOption === 'link'}
                              onChange={handleChange}
                            />
                            {t('ShareLink')}
                          </label>
                          <label>
                            <input
                              type="radio"
                              name="radioOption"
                              value="file"
                              datatype="file"
                              className="me-2"
                              checked={formData.radioOption === 'file'}
                              onChange={handleChange}
                            />
                            {t('AttachFile')}
                          </label>
                        </div>

                        <div>
                          {formData.radioOption == 'file' ? (
                            <Fragment>
                              <div className="mb-3 block w-full rounded-md border-0 bg-white px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                <input
                                  type="file"
                                  name="file"
                                  onChange={(e: any) => {
                                    Register.setFieldValue(
                                      'file',
                                      e.target.files[0]
                                    )
                                  }}
                                  id="file"
                                  accept="application/pdf"
                                />
                                {Register.errors.file &&
                                  Register.touched.file && (
                                    <div className="text-sm text-red-600">
                                      {Register.errors.file}
                                    </div>
                                  )}
                              </div>

                              <Link to={userData?.file} target="_blank">
                                <div className="file_itm flex items-center justify-between">
                                  <div className="fle_icon me-3 flex items-center">
                                    <span>
                                      <img src={upl} alt="file icon" />
                                    </span>
                                    <div className="fle_txt ms-4">
                                      <p>
                                        <span>{t('AttachBusinessFile')}</span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </Fragment>
                          ) : (
                            <div>
                              <input
                                type="text"
                                name="link"
                                id="link"
                                onChange={(event) => {
                                  const {value} = event.target
                                  Register.setFieldValue('link', value ?? '')
                                }}
                                value={Register?.values?.link ?? ''} // Ensures value is never undefined
                                placeholder="http://"
                                autoComplete="family-name"
                                className="mb-3 block w-full rounded-md border-0 px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                              {Register.errors.link &&
                                Register.touched.link && (
                                  <div className="text-sm text-red-600">
                                    {Register.errors.link}
                                  </div>
                                )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex-2 lg:w-50 w-full items-center lg:flex-1">
                    <div className="px-4">
                      <div className="m-auto my-8">
                        <label
                          htmlFor="commissioner_attribute_id"
                          className="mb-2 block text-start text-sm font-medium leading-6 text-gray-900"
                        >
                          {t('commissionerlattelabel')}
                        </label>
                        <select
                          className="mb-3 block w-full rounded-md border-0 px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          name="commissioner_attribute_id"
                          id="commissioner_attribute_id"
                          onChange={(event) => {
                            const {value} = event.target
                            value === userData?.commissioner_attribute_id.id
                            setCommissionerAttribute(value)
                            Register.setFieldValue(
                              'commissioner_attribute_id',
                              value
                            )
                          }}
                          onBlur={Register.handleBlur}
                          value={commissionerAttribute}
                        >
                          <option value="0">
                            {t('commissionerlattelabel')}
                          </option>
                          {attributes &&
                            attributes.map((item: any, index: number) => (
                              <option key={index} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                        </select>
                        {Register.errors.commissioner_attribute_id &&
                          Register.touched.commissioner_attribute_id && (
                            <div className="text-sm text-red-600">
                              {Register.errors.commissioner_attribute_id}
                            </div>
                          )}
                      </div>
                      <div className="m-auto my-8">
                        <label
                          htmlFor="id_number"
                          className="mb-2 block text-start text-sm font-medium leading-6 text-gray-900"
                        >
                          {t('commissionercard')}
                        </label>
                        <input
                          type="text"
                          name="id_number"
                          id="id_number"
                          onChange={(event) => {
                            const {value} = event.target
                            setIdNumber(value)
                            Register.setFieldValue(
                              'id_number',
                              value.replace(/[^0-9]/g, '')
                            )
                          }}
                          onBlur={Register.handleBlur}
                          value={idNumber}
                          maxLength={10}
                          placeholder={t('commissionercard')}
                          autoComplete="family-name"
                          className="mb-3 block w-full rounded-md border-0 px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        {Register.errors.id_number &&
                          Register.touched.id_number && (
                            <div className="text-sm text-red-600">
                              {Register.errors.id_number}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-center col-span-full m-auto">
                <button
                  type="submit"
                  className="bg-primary_color flex rounded-md bg-indigo-600 px-24 py-5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {t('editProfile')}
                  {success && <div className="loader"></div>}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
      <Footer />
    </div>
  )
}

export default Edit
